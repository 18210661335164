
        import * as module0 from './controllers/example_controller.js';import * as module1 from './controllers/filter_controller.js';import * as module2 from './controllers/nav_controller.js';import * as module3 from './controllers/observer_controller.js';import * as module4 from './controllers/teaser_controller.js'
        const modules = {
            "./controllers/example_controller.js": module0,
            "./controllers/filter_controller.js": module1,
            "./controllers/nav_controller.js": module2,
            "./controllers/observer_controller.js": module3,
            "./controllers/teaser_controller.js": module4,
        };
        export default modules;
      